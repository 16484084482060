<template>
<div class="page-content w-100">
  <!-- start heading -->
  <div class="row">
    <div class="col-sm-12">
    <p class="text-center page-heading">Message</p>
    </div>
   </div>
   <!--  -->
   <div class="p-4">
     <div class="row">
           <div class="col-10">
               <h4 class="text-color">Inbox</h4>
               <hr class="hr-border"/>
               
           </div>
           <div class="col-2">
               <router-link to="/doctor/newmessage" style="font-size:30px" id="btn_schedule_add" class="btn btn_add"><font-awesome-icon :icon='["fas","plus-circle"]'/></router-link>
           </div>
       </div>
   <div class="row">
       <div class="col-12">
            <ul>
                    <li class="list-style-none"><router-link class="font-weight-bold text-decoration-none text-color" to="/doctor/messagechat">User 1</router-link></li>
                    <li class="list-style-none"><router-link class="font-weight-bold text-decoration-none text-color" to="/doctor/messagechat">User 2</router-link></li>
                    <li class="list-style-none"><router-link class="font-weight-bold text-decoration-none text-color" to="/doctor/messagechat">User 3</router-link></li>
                    <li class="list-style-none"><router-link class="font-weight-bold text-decoration-none text-color" to="/doctor/messagechat">User 4</router-link></li>
                    <li class="list-style-none"><router-link class="font-weight-bold text-decoration-none text-color" to="/doctor/messagechat">User 5</router-link></li>
                </ul>
       </div>
   </div>
 </div>
</div>
</template>